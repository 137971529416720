
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code1 } from "./data";

export default defineComponent({
  name: "basic-usage",
  data() {
    return {
      color1: "#409EFF",
      color2: null
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code1
    };
  }
});
